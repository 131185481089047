<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
    <vxe-modal ref="xModal" :title='$t("familyMember")' width="1000" height="600" show-zoom @close="closeEvent">
      <template v-slot>
        <Family :selectRow="selectRow" />
      </template>
    </vxe-modal>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import Family from './Family'
export default {
  components: {
    Family
  },
  data() {
    return {
      loading: false,
      selectRow: {},
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == '1001') {
                    let { items, data } = this.formOptions;
                    this.$Tools.enabledEvent(items, ["familyNum"], false)
                    data["communityId"] = this.currNode.communityId;
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error('请选择所属小区')
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "name",
            title: this.$t("ownerName"),
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: this.$t("ownerNamePlaceholder"),
              },
            },
          },
          {
            field: "phone",
            title: "手机号",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 11,
                placeholder: "请输入手机号",
              },
            },
          },
          {
            field: "idCard",
            title: "身份证号",
            minWidth: 160,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return cellValue
              }
            },
            editRender: {
              name: "$input",
              props: {
                maxlength: 18,
                placeholder: "请输入身份证号",
              },
            },
          },
          {
            field: "sex",
            title: "性别",
            minWidth: 100,
            editRender: {
              name: '$select',
              optionTypeCode: "sex",
              props: {
                placeholder: "请选择性别",
              },
            },
          },
          {
            field: "familyNum",
            title: this.$t("familyMemberNum"),
            minWidth: 100,
            editRender: {
              name: "$VxeText",
              props: {
                type: 'text',
                status: "primary",
              },
              events: {
                click: (row) => {
                  this.selectRow = row;
                  this.$refs.xModal.open();
                }
              }
            },
          },
          {
            field: "communityId",
            title: "所属小区",
            minWidth: 120,
            editRender: {
              enabled: false,
              name: "$VxeSelectTree",
              defaultProps: {
                label: "name",
                value: "id",
              },
              expandAll: true,
              props: {
                placeholder: "请选择所属小区",
              },
              events: {
                click: (params, node) => {
                  let { row, column } = params;
                  if (node.type == '1001') {
                    row[column.property] = node.communityId;
                  } else {
                    this.$message.error('请选择所属小区')
                  }
                },
              }
            },
          },
          {
            field: "rent",
            title: "租金",
            minWidth: 160,
            visible: false,
          },
          {
            field: "registeredResidence",
            title: "户口所在地",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入户口所在地",
              },
            },
          },
          {
            field: "administrativeRegion",
            title: "行政区域",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入行政区域",
              },
            },
          },
          {
            field: "subdistrictOffice",
            title: "街道办",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入街道办",
              },
            },
          },
          {
            field: "communitys",
            title: "社区",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入社区",
              },
            },
          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   width: 100,
          //   cellRender: {
          //     name: "$switch",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       openLabel: "启用",
          //       closeLabel: "禁用",
          //       openValue: "1",
          //       closeValue: "0"
          //     },
          //     events: {
          //       change: ({ row }) => {
          //         this.$refs.xGrid.submitEvent(row)
          //       }
          //     }
          //   },
          // },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  status: "primary",
                  label: "家庭成员",
                  name: 'family',
                  events: {
                    click: (row) => {
                      this.selectRow = row;
                      this.$refs.xModal.open();
                    }
                  },
                },
                {
                  status: "primary",
                  label: "修改",
                  eventName: "queryEvent",
                  name: 'edit',
                  events: {
                    click: () => {
                      let { items } = this.formOptions;
                      this.$Tools.enabledEvent(items, ["familyNum"], true)
                    }
                  }
                },
                {
                  status: "danger",
                  label: "删除",
                  name: 'delete',
                  eventName: "removeEvent",
                },
              ],
            },
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 1000,
        height: 700,
        titleWidth: 130,
        items: [{
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "基本信息",
                position: "left"
              }
            },
          },
          {
            field: "communityId",
            title: "所属小区",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属小区",
              },
              events: {
                click: (params, node) => {
                  let { data, property } = params;
                  if (node.type == '1001') {
                    data[property] = node.communityId;
                  } else {
                    this.$message.error('请选择所属小区')
                  }
                },
              }
            },
          },
          {
            field: "name",
            title: this.$t("ownerName"),
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: this.$t("ownerNamePlaceholder"),
              },
            },
          },
          {
            field: "phone",
            title: "联系电话",
            span: 12,
            itemRender: {
              name: "$VxePhoneInput",
              props: {
                required: true,
                placeholder: "请输入手机号",
              },
            },
          },
          {
            field: "idCard",
            title: "身份证号",
            span: 12,
            itemRender: {
              name: "$VxeIDCardInput",
              props: {
                placeholder: "请输入身份证号",
              },
            },
          },
          {
            field: "sex",
            title: "性别",
            span: 12,
            itemRender: {
              name: '$select',
              optionTypeCode: "sex",
              props: {
                placeholder: "请选择性别",
              },
            },
          },
          {
            field: "age",
            title: "年龄",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: 'number',
                min: 0,
                max: 120,
                placeholder: "请输入年龄",
              },
            },
          },
          {
            field: "nation",
            title: "民族",
            span: 12,
            itemRender: {
              name: '$input',
              props: {
                maxlength: 10,
                placeholder: "请输入民族",
              },
            },
          },
          {
            field: "physicalCondition",
            title: "身体状况",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入身体状况",
              },
            },
          },
          {
            field: "familyNum",
            title: this.$t("familyMemberNum"),
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: 'number',
                min: 0,
                max: 10,
                placeholder: this.$t("familyMemberNumPlaceholder"),
              },
            },
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "区域信息",
                position: "left"
              }
            },
          },
          {
            field: "registeredResidence",
            title: "户口所在地",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入户口所在地",
              },
            },
          },
          {
            field: "administrativeRegion",
            title: "行政区域",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入行政区域",
              },
            },
          },
          {
            field: "subdistrictOffice",
            title: "街道办",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入街道办",
              },
            },
          },
          {
            field: "communitys",
            title: "社区",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入社区",
              },
            },
          },
          {
            field: "headPortraitId",
            title: "照片",
            span: 24,
            align: "center",
            itemRender: {
              name: "$VxeUpload",
              events: {
                click: (params, data) => {
                  params.data["headPortraitId"] = data;
                }
              }
            },
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "党员信息",
                position: "left"
              }
            },
          },
          {
            field: "partymembertype",
            title: "党员类型",
            span: 12,
            itemRender: {
              name: '$select',
              optionTypeCode: "partyMemberType",
              props: {
                placeholder: "请选择党员类型",
              },
              events: {
                change: ({ data, property }) => {
                  if (data[property] == "0") {
                    data["joinPartyTime"] = ""
                    data["partyPosts"] = ""
                  }
                }
              }
            },
          },
          {
            field: "joinPartyTime",
            title: "入党日期",
            span: 12,
            visibleMethod({ data }) {
              if (data.partymembertype == 0) {
                return false
              } else {
                return true
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择入党日期",
              },
            },
          },
          {
            field: "partyPosts",
            title: "党内职务",
            visibleMethod({ data }) {
              if (data.partymembertype == 0) {
                return false
              } else {
                return true
              }
            },
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入党内职务",
              },
            },
          },
          {
            field: 'remark',
            title: '描述',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入描述内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          communityId: [
            { required: true, message: "请选择所属小区" }
          ],
          name: [
            { required: true, message: this.$t("ownerNamePlaceholder") }
          ],
          phone: [
            { required: true, message: "请输入有效的联系电话" }
          ],
          link: [
            { required: true, message: "请输入手机号" }
          ],
          sex: [
            { required: true, message: "请选择性别" }
          ],
          ownerTypeCd: [
            { required: true, message: this.$t("ownerTypePlaceholder") }
          ],
          parentId: [{ required: true, message: "请选择所属上级" }],
        },
        // 新增功能字段初始化
        data: {
          status: "1",
          enabled: "1",
          ownerTypeCd: "1",
          sex: "1",
          partymembertype: "0",
          joinPartyTime: "",
          type: "1005",
          communityId: '',
          familyNum: 0
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: this.$t("ownerName"),
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: this.$t("ownerNamePlaceholder"),
              },
            },
          },
          {
            field: "phone",
            title: "手机号",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入手机号",
              },
            },
          },
          {
            field: "sex",
            title: "性别",
            itemRender: {
              name: '$select',
              optionTypeCode: "sex",
              props: {
                maxlength: 24,
                placeholder: "请选择性别",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "选择状态",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: "",
          phone: "",
          sex: "",
          enabled: ""
        },
      },
    };
  },
  computed: {
    ...mapState(["logo"]),
  },
  created() {
    // 西房特殊功能
    if (this.logo.code == "xf") {
      let { columns } = this.gridOptions;
      let item = this.$XEUtils.filterTree(columns, item => item.field === "rent")[0];
      item.visible = true;
    }
  },
  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "communityId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "communityId");
      this.searchOptions.data["parentId"] = node.id;
      this.$refs.xGrid.getLists();
    },

    closeEvent() {
      this.$refs.xGrid.getLists();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
