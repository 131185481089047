<template>
<div class="main">
  <VxeBasicTable ref="xGrid" service="/communitys/tBuildingOwner" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
  </VxeBasicTable>
</div>
</template>

<script>
export default {
  props: ["selectRow"],
  data() {
    return {
      gridOptions: {
        height: 600,
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "name",
            title: "姓名",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入姓名",
              },
            },
          },
          {
            field: "phone",
            title: "手机号",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 11,
                placeholder: "请输入手机号",
              },
            },
          },
          {
            field: "idCard",
            title: "身份证号",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 18,
                placeholder: "请输入身份证号",
              },
            },
          },
          {
            field: "sex",
            title: "性别",
            minWidth: 100,
            editRender: {
              name: '$select',
              optionTypeCode: "sex",
              props: {
                placeholder: "请选择性别",
              },
            },
          },
          {
            field: "age",
            title: "年龄",
            minWidth: 80,
            editRender: {
              name: "$input",
              props: {
                type: "integer",
                min: 1,
                maxlength: 3,
                placeholder: "请输入年龄",
              },
            },
          },
          {
            field: "ownerTypeCd",
            title: "成员类型",
            minWidth: 120,
            editRender: {
              name: '$select',
              optionTypeCode: "ownerType",
              props: {
                placeholder: "请选择类型",
              },
            },
          },
          {
            field: "registeredResidence",
            title: "户口所在地",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入户口所在地",
              },
            },
          },
          {
            field: "administrativeRegion",
            title: "行政区域",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入行政区域",
              },
            },
          },
          {
            field: "subdistrictOffice",
            title: "街道办",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入街道办",
              },
            },
          },
          {
            field: "communitys",
            title: "社区",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入社区",
              },
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
            },
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 1000,
        height: 700,
        mask: false,
        titleWidth: 130,
        items: [{
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "基本信息",
                position: "left"
              }
            },
          },
          {
            field: "name",
            title: this.$t("ownerName"),
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: this.$t("ownerNamePlaceholder"),
              },
            },
          },
          {
            field: "ownerTypeCd",
            title: "成员类型",
            span: 12,
            itemRender: {
              name: '$select',
              optionTypeCode: "ownerType",
              props: {
                placeholder: "请选择类型",
              },
            },
          },
          {
            field: "phone",
            title: "联系电话",
            span: 12,
            itemRender: {
              name: "$VxePhoneInput",
              props: {
                required: true,
                placeholder: "请输入手机号",
              },
            },
          },
          {
            field: "idCard",
            title: "身份证号",
            span: 12,
            itemRender: {
              name: "$VxeIDCardInput",
              props: {
                placeholder: "请输入身份证号",
              },
            },
          },
          {
            field: "sex",
            title: "性别",
            span: 12,
            itemRender: {
              name: '$select',
              optionTypeCode: "sex",
              props: {
                placeholder: "请选择性别",
              },
            },
          },
          {
            field: "age",
            title: "年龄",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: 'number',
                min: 0,
                max: 120,
                placeholder: "请输入年龄",
              },
            },
          },
          {
            field: "nation",
            title: "民族",
            span: 12,
            itemRender: {
              name: '$input',
              props: {
                maxlength: 10,
                placeholder: "请输入民族",
              },
            },
          },
          {
            field: "physicalCondition",
            title: "身体状况",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入身体状况",
              },
            },
          },
          {
            field: "headPortraitId",
            title: "照片",
            span: 24,
            align: "center",
            itemRender: {
              name: "$VxeUpload",
              events: {
                click: (params, data) => {
                  params.data["headPortraitId"] = data;
                }
              }
            },
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "区域信息",
                position: "left"
              }
            },
          },
          {
            field: "registeredResidence",
            title: "户口所在地",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入户口所在地",
              },
            },
          },
          {
            field: "administrativeRegion",
            title: "行政区域",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入行政区域",
              },
            },
          },
          {
            field: "subdistrictOffice",
            title: "街道办",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入街道办",
              },
            },
          },
          {
            field: "communitys",
            title: "社区",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入社区",
              },
            },
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "党员信息",
                position: "left"
              }
            },
          },
          {
            field: "partymembertype",
            title: "党员类型",
            span: 12,
            itemRender: {
              name: '$select',
              optionTypeCode: "partyMemberType",
              props: {
                placeholder: "请选择党员类型",
              },
              events: {
                change: ({ data, property }) => {
                  if (data[property] == "0") {
                    data["joinPartyTime"] = ""
                    data["partyPosts"] = ""
                  }
                }
              }
            },
          },
          {
            field: "joinPartyTime",
            title: "入党日期",
            span: 12,
            visibleMethod({ data }) {
              if (data.partymembertype == 0) {
                return false
              } else {
                return true
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择入党日期",
              },
            },
          },
          {
            field: "partyPosts",
            title: "党内职务",
            visibleMethod({ data }) {
              if (data.partymembertype == 0) {
                return false
              } else {
                return true
              }
            },
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入党内职务",
              },
            },
          },
          {
            field: 'remark',
            title: '描述',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入描述内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          communityId: [
            { required: true, message: "请选择所属小区" }
          ],
          name: [
            { required: true, message: this.$t("ownerNamePlaceholder") }
          ],
          link: [
            { required: true, message: "请输入手机号" }
          ],
          sex: [
            { required: true, message: "请选择性别" }
          ],
          ownerTypeCd: [
            { required: true, message: this.$t("ownerTypePlaceholder") }
          ],
          parentId: [{ required: true, message: "请选择所属上级" }],
        },
        // 新增功能字段初始化
        data: {
          status: "1",
          enabled: "1",
          ownerTypeCd: "",
          sex: "1",
          partymembertype: "0",
          joinPartyTime: "",
          type: "1005",
          parentId: this.selectRow.id,
          communityId: this.selectRow.communityId,
          phone: "",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [],
        data: {
          communityId: this.selectRow.communityId,
          parentId: this.selectRow.id,
          // ownerTypeCd: "3",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
